import React from "react";
import PageWrapper from "../components/PageWrapper";
import CTA from "../sections/landing3/CTA";
import MapGoogle from "../components/MapGoogle";


const LajmetPage = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "danger",
          isFluid: true,
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <br />
        <br />
        
        <CTA />
        <div
          className="google-map fluid-map-height position-relative overflow-hidden"
          id="googleMap"
          css={`
            position: relative;
            overflow: hidden;
          `}
        >
          <div
            css={`
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0px;
              left: 0px;
              background-color: rgb(11, 184, 155);
            `}
          >
            <MapGoogle />
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default LajmetPage;